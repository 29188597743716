<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="dark"
		:style="{ backgroundColor: 'transparent',}">
			<div class="brand" style="display:flex"><img src="images/Revelater_lockup_04.png" alt=""> </div>
			<hr>

			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline">
				<a-menu-item>
					<router-link to="/dashboard">

						<span class="icon">
							<font-awesome-icon icon="chart-line" />
						</span>
						<span class="label">Dashboard</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/products">
						<span class="icon">
							<font-awesome-icon icon="list"/>
						</span>
						<span class="label">Products</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/categories">
						<span class="icon">
							<font-awesome-icon icon="tags"/>
						</span>
						<span class="label">Categories</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/competitors">
						<span class="icon">
							<font-awesome-icon icon="sitemap"/>
						</span>
						<span class="label">Competitors</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/goals">
						<span class="icon">
							<font-awesome-icon icon="flag"/>
						</span>
						<span class="label">Goals</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/actions">
						<span class="icon">
							<font-awesome-icon icon="flag"/>
						</span>
						<span class="label">Actions</span>
					</router-link>
				</a-menu-item>
				<a-menu-item class="menu-item-header">
					Account Pages
				</a-menu-item>
				<a-menu-item>
					<router-link to="/profile">
						<span class="icon">
							<font-awesome-icon icon="user"/>
						</span>
						<span class="label">Profile</span>
					</router-link>
				</a-menu-item>
			</a-menu>
			<!-- / Sidebar Navigation Menu -->

			<!-- Sidebar Footer -->
			<div class="aside-footer">
				<div class="footer-box">
					<span class="icon">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z" fill="#111827"/>
							<path d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z" fill="#111827"/>
							<path d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z" fill="#111827"/>
						</svg>
					</span>
					<h6>Need Help?</h6>
					<p>Please check our docs</p>
					<a-button type="primary" href="" block target="_blank">
						DOCUMENTATION
					</a-button>
				</div>
			</div>
			<!-- / Sidebar Footer -->

			

	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>
	export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
		data() {
			return {
				// sidebarCollapsedModel: this.sidebarCollapsed,
			}
		},
	})

</script>
