import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCjvsVRa2zlDV648jVzDhQD8aKaIUFPQAM",
    authDomain: "revelater-a7ced.firebaseapp.com",
    projectId: "revelater-a7ced",
    storageBucket: "revelater-a7ced.appspot.com",
    messagingSenderId: "69862047351",
    appId: "1:69862047351:web:060034dbeffd29b1eb9072",
    measurementId: "G-SVHX6M2769"
  };
  
  firebase.initializeApp(firebaseConfig);


  export {firebase};
